import type { NextPage } from "next";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const router = useRouter();
  if (typeof window !== "undefined") {
    router.replace("/contacts");
  }

  return null;
};

export default Home;
